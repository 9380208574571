import {  Group, Menu, Center, Burger, ScrollArea, UnstyledButton, Divider, rem, Box, useMantineTheme, Drawer, Collapse} from '@mantine/core';
import classes from './Header.module.css';
import { useNavigate } from 'react-router-dom';
import { IconChevronDown } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';

const links = [
  { link: '/', label: 'Home' },
  {
    link: '#1',
    label: 'Tools',
    links: [
      { link: '/inflation', label: 'Inflation Calculator' },
      { link: '/property-profit-calculator', label: 'Property Profit Calculator' },
      { link: '/s&p-relative-performance-tracker', label: 'S&P 500 Relative Performance Tracker' },
    ],
  },
]

const toolLinks = [
  { link: '/inflation', label: 'Inflation Calculator' },
  { link: '/property-profit-calculator', label: 'Property Profit Calculator' },
  { link: '/s&p-relative-performance-tracker', label: 'S&P 500 Relative Performance Tracker' }
]

export function Header() {
  const navigate = useNavigate();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const theme = useMantineTheme();

  const toolLinkItems = toolLinks.map((item) => (
    <a href={item.link} className={classes.link}>
      {item.label}
    </a>
  ));

  const items = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item key={item.link}>
        <a
          href={item.link}
          className={classes.link}
          onClick={(event) => {
            event.preventDefault();
            navigate(item.link)
          }}
        >
          {item.label}
        </a>
      </Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu key={link.label} trigger="hover" transitionProps={{ exitDuration: 0 }} withinPortal>
          <Menu.Target>
            <a
              href={link.link}
              className={classes.link}
              onClick={(event) => {
                event.preventDefault();
                navigate(link.link)
              }}
            >
              <Center>
                <span className={classes.linkLabel}>{link.label}</span>
                <IconChevronDown size="0.9rem" stroke={1.5} />
              </Center>
            </a>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }


    return (
      <a
        key={link.label}
        href={link.link}
        className={classes.link}
        onClick={(event) => {
          event.preventDefault();
          navigate(link.link)
        }}
      >
        {link.label}
      </a>
    );
  });

  return (
    <>
      <header className={classes.header}>
        <div className={classes.inner}>
          <Group>
            <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
            <div></div>
            <h2>ProInvestRE</h2>
          </Group>

          <Group>
            <Group ml={50} gap={5} className={classes.links} visibleFrom="sm">
              {items}
            </Group>
            <div></div>
          </Group>
        </div>
      </header>
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        hiddenFrom="sm"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <Divider my="sm" />

          <a href="/" className={classes.link}>
            Home
          </a>
          <UnstyledButton className={classes.link} onClick={toggleLinks}>
            <Center inline>
              <Box component="span" mr={5}>
                Tools
              </Box>
              <IconChevronDown
                style={{ width: rem(16), height: rem(16) }}
                color={theme.colors.blue[6]}
              />
            </Center>
          </UnstyledButton>
          <Collapse in={linksOpened}>{toolLinkItems}</Collapse>
        </ScrollArea>
      </Drawer>
    </>
  );
}