import { Container, Title, Text, Button, Group } from '@mantine/core';
import classes from './ToolComingSoonPage.module.css';
import { useNavigate } from 'react-router-dom';

export function ToolComingSoonPage() {
    const navigate = useNavigate()

    const handleReturnToHome = () => {
        navigate('/')
    }

    return (
        <Container className={classes.root}>
        <div className={classes.inner}>
            <div className={classes.content}>
            <Title className={classes.title}>Unfortunately, this tool isn't ready yet!</Title>
            <Text c="dimmed" size="lg" ta="center" className={classes.description}>
                Please allow us more time to complete this tool.
            </Text>
            <Group justify="center">
                <Button onClick={handleReturnToHome}size="md">Take me back to home page</Button>
            </Group>
            </div>
        </div>
        </Container>
    );
}