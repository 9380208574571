const rawCpiIndexData =[
    {
      "Year": 1988,
      "CPI": 48.2
    },
    {
      "Year": 1989,
      "CPI": 51
    },
    {
      "Year": 1990,
      "CPI": 55.1
    },
    {
      "Year": 1991,
      "CPI": 59.2
    },
    {
      "Year": 1992,
      "CPI": 61.9
    },
    {
      "Year": 1993,
      "CPI": 63.5
    },
    {
      "Year": 1994,
      "CPI": 64.9
    },
    {
      "Year": 1995,
      "CPI": 66.6
    },
    {
      "Year": 1996,
      "CPI": 68.5
    },
    {
      "Year": 1997,
      "CPI": 70
    },
    {
      "Year": 1998,
      "CPI": 71.3
    },
    {
      "Year": 1999,
      "CPI": 72.6
    },
    {
      "Year": 2000,
      "CPI": 73.4
    },
    {
      "Year": 2001,
      "CPI": 74.6
    },
    {
      "Year": 2002,
      "CPI": 75.7
    },
    {
      "Year": 2003,
      "CPI": 76.7
    },
    {
      "Year": 2004,
      "CPI": 77.8
    },
    {
      "Year": 2005,
      "CPI": 79.4
    },
    {
      "Year": 2006,
      "CPI": 81.4
    },
    {
      "Year": 2007,
      "CPI": 83.3
    },
    {
      "Year": 2008,
      "CPI": 86.2
    },
    {
      "Year": 2009,
      "CPI": 87.9
    },
    {
      "Year": 2010,
      "CPI": 90.1
    },
    {
      "Year": 2011,
      "CPI": 93.6
    },
    {
      "Year": 2012,
      "CPI": 96
    },
    {
      "Year": 2013,
      "CPI": 98.2
    },
    {
      "Year": 2014,
      "CPI": 99.6
    },
    {
      "Year": 2015,
      "CPI": 100
    },
    {
      "Year": 2016,
      "CPI": 101
    },
    {
      "Year": 2017,
      "CPI": 103.6
    },
    {
      "Year": 2018,
      "CPI": 106
    },
    {
      "Year": 2019,
      "CPI": 107.8
    },
    {
      "Year": 2020,
      "CPI": 108.9
    },
    {
      "Year": 2021,
      "CPI": 111.6
    },
    {
      "Year": 2022,
      "CPI": 120.5
    },
    {
      "Year": 2023,
      "CPI": 128.6
    },
    {
      "Year": 2024,
      "CPI": 130.8
    }
]

const getCPIData = () => {
    const cpiDataAsMap = new Map<number, number>();
    rawCpiIndexData.forEach(cpiRecord => {
      if (typeof cpiRecord.Year !== 'number' || typeof cpiRecord.CPI !== 'number') {
        throw new Error(`Invalid data format for record: ${cpiRecord}`)
      }
      cpiDataAsMap.set(cpiRecord.Year, cpiRecord.CPI)
    })
    return cpiDataAsMap
}

let CPIDataAsMap: Map<number, number>;

try {
  CPIDataAsMap = getCPIData();
} catch (error) {
  console.error('Error loading CPI data: ', error)
  CPIDataAsMap = new Map();
}

export const getIndexByYear = (year:number): number | undefined => {
  const res = CPIDataAsMap.get(year);
  return res ? res : undefined;
}