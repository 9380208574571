import React from 'react';
import './App.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Header } from './components/Header';
import { MantineProvider } from '@mantine/core';
import { InflationTracker } from './components/InflationTracker';
import { PageNotFound } from './components/PageNotFound';
import { ToolsPage } from './components/ToolsPage';
import { Route, Routes } from 'react-router-dom';
import { ToolComingSoonPage } from './components/ToolComingSoonPage';


function App() {
  return (
    <MantineProvider>
      <Header/>
      <Routes>
        <Route path='/' element={<ToolsPage/>}></Route>
        <Route path='/inflation' element={<InflationTracker/>}></Route>
        <Route path='/property-profit-calculator' element={<ToolComingSoonPage/>}></Route>
        <Route path='/s&p-relative-performance-tracker' element={<ToolComingSoonPage/>}></Route>
        <Route path='*' element={<PageNotFound/>}></Route>
      </Routes>
    </MantineProvider>
  );
}

export default App;
