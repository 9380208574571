import {
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
    NumberInput,
} from '@mantine/core';
import classes from './InflationTracker.module.css';
import { useState } from 'react';
import { getIndexByYear } from '../data/cpi';


export const InflationTracker = () => {
    const currentYear = new Date().getFullYear();
    // Input state
    const [purchasePxInput, setPurchasePxInput] = useState(0);
    const [salePxInput, setSalePx] = useState(0);
    const [startYearInput, setStartYearInput] = useState(currentYear - 1);
    const [endYearInput, setEndYearInput] = useState(currentYear);
    // Result state
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [result, setResult] = useState(-1);
    const [formattedResult, setFormattedResult] = useState('');
    const [formattedPurchasePx, setFormattedPurchasePx] = useState('')
    const [formattedSaleePx, setFormattedSaleePx] = useState('')
    const [submittedSalePx, setSubmittedSalePx] = useState(0)
    const [startYear, setStartYear] = useState(currentYear - 1);
    const [endYear, setEndYear] = useState(currentYear)

    const handleCalculate = (e: any) => {
        const startYearIndex = getIndexByYear(startYearInput)
        const endYearIndex = getIndexByYear(endYearInput)
        if (startYearIndex === undefined || endYearIndex === undefined) {
            console.warn(`Unable to retrieve CPI index for year ${startYearInput} or ${endYearInput}`)
        } 
        if (startYearIndex && endYearIndex) {
            const res = purchasePxInput * (endYearIndex/ startYearIndex)
            const formattedResult = Intl.NumberFormat('en-UK', { 
                style: 'currency', 
                currency: 'GBP',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(res)
            setResult(res)
            setFormattedResult(formattedResult)
            const formattedAmt = Intl.NumberFormat('en-UK', { 
                style: 'currency', 
                currency: 'GBP',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(purchasePxInput)
            const formattedSaleAmt = Intl.NumberFormat('en-UK', { 
                style: 'currency', 
                currency: 'GBP',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(salePxInput)
            setFormattedPurchasePx(formattedAmt)
            setFormattedSaleePx(formattedSaleAmt)
            setStartYear(startYearInput)
            setEndYear(endYearInput)
            setSubmittedSalePx(salePxInput)
            setIsSubmitted(true)
        }

    }
    
    return (
        <Container size={420} my={40}>
            <Title ta="center" className={classes.title}>
                Inflation Tracker
            </Title>
            <Text c="dimmed" size="sm" ta="center" mt={5}>
                This calculator shows the effect of inflation on the real value of your cash and other GBP denominated assets.{' '}
            </Text>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <NumberInput label="Asset Purchase Price" description="Amount the asset sold for e.g. a house, gold or stock" value={purchasePxInput} onChange={(amount) => {setPurchasePxInput(amount as number)}} thousandSeparator hideControls allowNegative={false} prefix='£'></NumberInput>
                <Group justify="space-between" mt="lg"/>
                <NumberInput label="Sale Price/ Current Valuation" description="Enter the price you sold the asset for or it's current value" value={salePxInput} onChange={(amount) => {setSalePx(amount as number)}} thousandSeparator hideControls allowNegative={false} prefix='£'></NumberInput>
                <Group justify="space-between" mt="lg"/>
                <NumberInput label="Purchase Year" description="In what year did you purchase the asset? Must be the full year i.e. 2005" value={startYearInput} onChange={(val) => {setStartYearInput(val as number)}} min={1980} max={2024} allowNegative={false}></NumberInput>
                <Group justify="space-between" mt="lg"/>
                <NumberInput label="Sale Year" description="If you have not sold the asset yet, enter the current year. Must be the full year i.e. 2005" value={endYearInput} onChange={(val) => {setEndYearInput(val as number)}} min={1980} max={2024} allowNegative={false}></NumberInput>
                <Button onClick={handleCalculate}fullWidth mt="xl">
                    Calculate
                </Button>
                { isSubmitted && (submittedSalePx >= result) &&
                    <div>
                        <h3 className={classes.resultTxt}>Congratulations!</h3>
                        <p>{formattedPurchasePx} in {startYear} would be worth {formattedResult} in {endYearInput} after inflation.</p>
                        <p>Since your asset is worth { formattedSaleePx } as of {endYearInput}, your asset has outperformed inflation meaning that you have made a real terms profit.</p>
                        <p>Good Job!</p>
                    </div>
                }
                { isSubmitted && (submittedSalePx < result) &&
                    <div>
                        <h3 className={classes.resultTxt}>Unfortunately...</h3>
                        <p>{formattedPurchasePx} in {startYear} would be worth {formattedResult} in {endYearInput} after inflation.</p>
                        <p>Since your asset is worth { formattedSaleePx } as of {endYear}, your asset has not outperformed inflation meaning that you have not made a real terms profit.</p>
                        <p>There can be many reasons for this such as poor market conditions or macroeconomic factors. Additionally, some investments tend to offer lower returns than others.</p>
                    </div>
                }
            </Paper>
        </Container>
    );
}