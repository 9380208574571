import { SimpleGrid, Card, Text, Container, AspectRatio, ThemeIcon, rem } from '@mantine/core';
import classes from './ToolsPage.module.css';
import { IconChartLine, IconBuildingCommunity, IconBusinessplan } from '@tabler/icons-react';

enum ToolTheme {
    Inflation,
    Property,
    Stock
}

const mockdata = [
    {
        title: 'Inflation Calculator',
        theme: ToolTheme.Inflation,
        isLive: true,
        description: 'Calculate whether your investments have kept up with inflation. Suitable for investment purchased after or during 1988.',
        link: '/inflation'
    },
    {
        title: 'Property Profit Calculator',
        theme: ToolTheme.Property,
        isLive: false,
        description: 'Calculate how much profit you have made from your residential property after inflation and all ownership specific costs.',
        link: '/property-profit-calculator'
    },
    {
        title: 'S&P 500 Relative Performance Tracker',
        theme: ToolTheme.Stock,
        isLive: false,
        description: 'Compare your investment returns the S&P500. Have you outperformed the famous index?',
        link: '/s&p-relative-performance-tracker'
    },
];

export function ToolsPage() {

    const cards = mockdata.map((article) => (
        <Card key={article.title} p="md" radius="md" component="a" href="#" className={classes.card}>
            <AspectRatio ratio={1920 / 1080}>
                <ThemeIcon
                    size="xl"
                    radius="md"
                    variant="gradient"
                    gradient={{ deg: 0, from: 'pink', to: 'orange' }}
                >
                    {article.theme === ToolTheme.Inflation && 
                        <IconChartLine style={{ width: rem(28), height: rem(28) }} stroke={1.5} />
                    }
                    {article.theme === ToolTheme.Property && 
                        <IconBuildingCommunity style={{ width: rem(28), height: rem(28) }} stroke={1.5} />
                    }
                    {article.theme === ToolTheme.Stock && 
                        <IconBusinessplan style={{ width: rem(28), height: rem(28) }} stroke={1.5} />
                    }
                </ThemeIcon>
            </AspectRatio>
            <Text c="dimmed" size="xs" tt="uppercase" fw={700} mt="md">
                { article.isLive ? 
                    <div>Live</div>:
                    <div>Coming soon...</div>
                }
            </Text>
            <Text size="xl" fw={500} mt="md">
                <a 
                    href={article.link}
                >
                    {article.title}
                </a>
                <Text size="sm" mt="sm" c="dimmed">{article.description}</Text>
            </Text>
        </Card>
  ));

  return (
    <Container py="xl">
      <SimpleGrid cols={{ base: 1, sm: 2 }}>{cards}</SimpleGrid>
    </Container>
  );
}